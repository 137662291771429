import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatStepperModule,
    MatRadioModule,
    MatDatepickerModule,
    TextFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    MatExpansionModule,
    MatTooltipModule,
    MatButtonToggleModule
  ],
  exports: [
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatStepperModule,
    MatRadioModule,
    MatDatepickerModule,
    TextFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    MatExpansionModule,
    MatTableModule,
    MatTooltipModule,
    MatButtonToggleModule
  ]
})
export class AngularMaterialModule { }
