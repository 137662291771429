import {Component, Inject} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ResourceUploadService} from '../../../shared/services/resource-upload.service';

export interface ViewDocumentDialogData {
  id: number
}

@Component({
  selector: 'app-view-document-dialog',
  templateUrl: './view-document-dialog.component.html',
  styleUrls: ['../../documents/documents.component.scss']
})

export class ViewDocumentDialogComponent {
  form: UntypedFormGroup
  subs = new Subscription()
  errorPresent = false
  errorMsg
  file: File

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ViewDocumentDialogData,
    private resourceUploadService: ResourceUploadService
  ) {
    this.resourceUploadService.downloadDuaFile(data.id)
      .then(blob => {
        this.file = new File([blob], 'Data Usage Agreement');
      })
      .catch(error => {
        console.error('Download error:', error);
      })
  }
}
