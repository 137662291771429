import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessGuard } from './guards/access/access.guard';
import { FaqComponent } from './faq/faq.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app/login',
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'bundles',
    loadChildren: () =>
      import('./bundles/bundles.module').then((m) => m.BundlesModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'pilot-sites',
    loadChildren: () =>
      import('./pilot-sites/pilot-sites.module').then(
        (m) => m.PilotSitesModule
      ),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'organization',
    loadChildren: () =>
      import('./organization/organization.module').then(
        (m) => m.OrganizationModule
      ),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'documents',
    loadChildren: () =>
      import('./documents/documents.module').then((m) => m.DocumentsModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'all-users',
    loadChildren: () =>
      import('./all-users/all-users.module').then((m) => m.AllUsersModule),
    data: { requiresLogin: true, requiresAccess: true }, // requiresAccess is the route guard flag
    canActivate: [AccessGuard],
  },

  {
    path: 'all-measures',
    loadChildren: () =>
      import('./all-measures/all-measures.module').then(
        (m) => m.AllMeasuresModule
      ),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'questionnaire',
    loadChildren: () =>
      import('./questionnaire/questionnaire.module').then(
        (m) => m.QuestionnaireModule
      ),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'pilot-site',
    loadChildren: () =>
      import('./pilot-site/pilot-site.module').then((m) => m.PilotSiteModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'reporting',
    loadChildren: () =>
      import('./reporting/reporting.module').then((m) => m.ReportingModule),
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'gov-entity-orgs',
    loadChildren: () =>
      import('./gov-entity-orgs/gov-entity-orgs.module').then(
        (m) => m.GovEntityOrgsModule
      ),
    data: { requiresLogin: true, requiresAccess: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'faq',
    pathMatch: 'full',
    component: FaqComponent,
  },
  {
    path: 'document-repository',
    loadChildren: () =>
      import('./document-repository/document-repository.module').then(
        (m) => m.DocumentRepositoryModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
