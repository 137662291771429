<app-navbar></app-navbar>
<div class="container faq-container">
  <h1 style="margin-top: 25px; font-size: 22pt">
    <img
      style="padding-bottom: 4pt"
      src="../../assets/icons/aim-sites-icon.svg"
    />
    FAQs
  </h1>
  <div class="instructions">
    <p>Here you will find answers to the most frequently asked questions.</p>
  </div>
  <div
    *ngIf="currentUser.highest_role === 'super_admin'"
    class="row create-site"
    style="margin-bottom: 20px"
  >
    <div class="col-auto">
      <div
        *ngIf="showAddForm === false && adminEdit === false && !selectedFaq"
        class="button mb-3 mb-md-0"
        (click)="showAddForm = !showAddForm"
        style="cursor: pointer; width: 230px"
      >
        Create a FAQ
        <img class="float-right" src="../../assets/icons/aim-plus-badge2.svg" />
      </div>
      <div
        *ngIf="showAddForm === true"
        class="button mb-3 mb-md-0"
        (click)="showAddForm = !showAddForm"
        style="cursor: pointer; width: 230px"
      >
        Cancel
        <img class="float-right" src="../../assets/icons/aim-exit-icon.svg" />
      </div>
    </div>
    <div
      *ngIf="showAddForm === false && !selectedFaq"
      (click)="setReorder()"
      class="col-auto"
    >
      <div class="button button-secondary" style="width: 230px">
        {{ reorderButtonMsg }}
        <img
          *ngIf="adminEdit === false"
          class="float-right"
          src="../../assets/icons/aim-refresh-icon2.svg"
        />
      </div>
    </div>
  </div>

  <div
    *ngIf="adminEdit; else notAdminEdit"
    class="dragedit-list"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
  >
    <mat-card class="drag-card" *ngFor="let faq of faqs" cdkDrag>
      <mat-card-header>
        Display Order: {{ faq.display_order }} &nbsp;&nbsp;&nbsp;&nbsp;{{
          faq.question
        }}
      </mat-card-header>
    </mat-card>
  </div>

  <ng-template #notAdminEdit>
    <div class="dragdrop-list">
      <div *ngIf="showAddForm">
        <app-faq-card
          [faqArrayLength]="faqs.length"
          (formSubmitted)="onFormSubmitted($event)"
          (faqAdded)="refreshFaqs()"
        ></app-faq-card>
      </div>
      <mat-card
        class="dragdrop-box"
        style="cursor: auto"
        *ngFor="let faq of faqs"
      >
        <mat-card-header>
          <div *ngIf="selectedFaq !== faq">
            <mat-card-title>
              {{ faq.question }}
            </mat-card-title>
            <mat-card-subtitle>{{ faq.answer }}</mat-card-subtitle>
          </div>
        </mat-card-header>
        <div *ngIf="currentUser.highest_role === 'super_admin'" class="icons">
          <img
            *ngIf="!selectedFaq"
            (click)="enterEditMode(faq)"
            style="margin-right: 10px"
            width="40vw"
            src="../../assets/icons/aim-edit-badge.svg"
          />
          <div *ngIf="selectedFaq === faq">
            <label>Question:</label>
            <input type="text" [(ngModel)]="editedQuestion" /><br />
            <label>Answer:</label>
            <textarea type="text" [(ngModel)]="editedAnswer"></textarea>
            <button class="input-card-button" (click)="saveEdit()">Save</button>
          </div>
          <img
            *ngIf="!selectedFaq"
            (click)="deleteFaq(faq)"
            width="30vw"
            src="../../assets/icons/aim-trash-can.svg"
          />
        </div>
      </mat-card>
    </div>
  </ng-template>
</div>
<app-footer></app-footer>
