export class User {
  id: number
  email: string
  first_name: string
  last_name: string
  phone: string
  token: string
  current_client_id: number
  highest_role: string
  highest_access_level: string
  constructor({
    id = 0,
    email = '',
    first_name = '',
    last_name = '',
    phone = '',
    current_client_id = 0,
    highest_role = '',
    highest_access_level = '',
    token = '',
    ...rest
  }) {
    Object.assign(this, rest)
    this.id = id;
    this.email = email;
    this.first_name = first_name
    this.last_name = last_name
    this.phone = phone
    this.current_client_id = current_client_id
    this.highest_role = highest_role
    this.highest_access_level = highest_access_level
    this.token = token
  }

}
