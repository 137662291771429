<!-- NAVBAR -->
<header>
  <div id="navbar" class="navbar fixed-top">
    <nav id="nav-main">
      <div class="container-fluid">
        <span (click)="navigateHome()" style="cursor: pointer">
          <a class="navbar-brand">
            <img class="pr-3" src="./assets/images/aim-logo-navbar.png" />
          </a>
        </span>
        <span (click)="logoutUser()" style="cursor: pointer; float: right">
          <a> Logout </a>
        </span>
      </div>
    </nav>
  </div>
</header>
