<footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex flex-column flex-md-row align-items center justify-content-md-between">
          <span class="mb-3 mb-md-0">Copyright &copy; 2023 AIM CCI</span>
          <ul class="d-flex">
            <li (click)="navigateTo('/faq')" style="cursor: pointer">
              <a>FAQ</a>
            </li>
            <li><a style="cursor: pointer" (click)="openSupportDialog()">Request Assistance</a></li>
          </ul>
        </div>
      </div>
    </div>
</footer>
