import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhonePipe } from './phone.pipe';
import { SearchStateArrayPipe } from './search-state-array.pipe';
import { SearchCountyArrayPipe } from './search-county-array.pipe';
import { SortByPipe } from './sort-by.pipe'




@NgModule({
  declarations: [
    PhonePipe,
    SearchStateArrayPipe,
    SearchCountyArrayPipe,
    SortByPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PhonePipe,
    SearchStateArrayPipe,
    SearchCountyArrayPipe,
    SortByPipe
  ]
})
export class PipesModule { }
