import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchStateArray'
})
export class SearchStateArrayPipe implements PipeTransform {

  transform(states: any[], searchText: string): any {
    if (!searchText) { return states }
    if (!states) { return [] }

    return states.filter(state =>
      state?.name.toLowerCase().indexOf(searchText.toLowerCase()) === 0
      || state?.abbreviation.toLowerCase().indexOf(searchText.toLowerCase()) === 0
    );
  }

}
