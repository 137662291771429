import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Libraries
import { FontAwesomeModule, FaIconLibrary, FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSpinner, faCircle, faMinus, faPlus, faInfoCircle,
  faSearch, faAngleLeft, faTrashAlt, faPencilAlt, faBars, faHome, faTimes,
  faTools, faTrash, faSyncAlt, faSync, faStopwatch, faCheck, faQuestionCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import {faAngleRight as farAngleRight, faAngleUp, faAngleDown} from '@fortawesome/pro-regular-svg-icons';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FontAwesomeModule
    ],
    exports: [
        FaIconComponent
    ]
})
export class FaIconsModule {
  constructor(
    private library: FaIconLibrary
  ) {
    this.library.addIcons(farAngleRight, faSpinner, faCircle, faMinus, faPlus, faInfoCircle,
      faSearch, faAngleLeft, faTrashAlt, faPencilAlt, faBars, faHome, faTimes,
      faTools, faTrash, faSyncAlt, faSync, faStopwatch, faCheck, faQuestionCircle, faAngleUp, faAngleDown, faStar );
  }
 }
