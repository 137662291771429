import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  private currentUserSubject: BehaviorSubject<User>
  public currentUser: Observable<User>
  private apiRoot: string

  constructor(
    private http: HttpClient,
    private storage: LocalStorageService,
  ) {
    this.apiRoot = `${environment.apiUrl}`
    this.currentUserSubject = new BehaviorSubject<User>(this.storage.getItem('currentUser'))
    this.currentUser = this.currentUserSubject.asObservable()
  }

  getOpenThreads(){
    return this.http.get<any>(`${this.apiRoot}/admin/support_requests/open_threads`)
  }

  getInternalThread(){
    return this.http.get<any>(`${this.apiRoot}/support_requests/internal_thread`)
  }

  getExternalThread(params){
    return this.http.get<any>(`${this.apiRoot}/support_requests/external_thread`, {params})
  }

  createInternalThread(params){
    return this.http.post<any>(`${this.apiRoot}/support_requests/create_internal`, params)
  }

  createExternalThread(params){
    return this.http.post<any>(`${this.apiRoot}/support_requests/create_external`, params)
  }

  respondToInternalThread(params){
    return this.http.post<any>(`${this.apiRoot}/support_requests/respond_to_internal_thread`, params)
  }

  respondToExternalThread(params){
    return this.http.post<any>(`${this.apiRoot}/support_requests/respond_to_external_thread`, params)
  }

  respondToThreadByID(params){
    return this.http.post<any>(`${this.apiRoot}/support_requests/respond_to_thread`, params)
  }

  refreshInvitationToken(params){
    return this.http.post<any>(`${this.apiRoot}/users/refresh_invitation`, params)
  }
}
