import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SupportService} from '../../../shared/services/support.service';
import Swal from 'sweetalert2';

export interface SupportDialogData {
  user: object,
  selectedThread: {
    id: number
  },
  internal: boolean
}

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['../footer/footer.component.scss']
})
export class SupportDialogComponent implements OnInit {
  form: UntypedFormGroup
  message: string
  ticketNumber: string
  emailInput: string
  subs = new Subscription()
  errorPresent = false
  errorMsg
  chatActive = false
  currentThread
  hasErrors = false
  hasTicketStringErrors = false
  hasEmailInputErrors = false
  internal = true
  externalChatActive = false
  ticketError = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SupportDialogData,
    private supportService: SupportService,
    public dialogRef: MatDialogRef<SupportDialogComponent>,
  ) {
    dialogRef.beforeClosed().subscribe(result => dialogRef.close(this.currentThread));
  }

  ngOnInit() {
    if (this.data.user){
      if (this.data.selectedThread){
        this.currentThread = this.data.selectedThread
        this.chatActive = true
      }else{
        this.subs.add(
          this.supportService.getInternalThread()
            .subscribe(data => {
              console.log(data)
              if (data) {
                this.currentThread = data.payload
                this.chatActive = true
              }
            }, error => {
              console.error(error)
            })
        )
      }
    }else{
      this.internal = false
    }
  }

  handleChange(){
    if (this.message !== undefined) {
      this.hasErrors = false
    }
  }

  handleTicketChange(){
    if (this.ticketNumber !== undefined) {
      this.hasTicketStringErrors = false
    }
  }

  handleEmailChange(){
    if (this.emailInput !== undefined) {
      this.hasEmailInputErrors = false
    }
  }

  handleSubmit(){
    if (this.message === undefined || this.message === '') {
      this.hasErrors = true
    }else{
      if (this.chatActive){
        if (this.internal){
          if (this.data.selectedThread){
            this.replyToThreadById()
          }else{
            this.replyToInternalThread()
          }
        }else{
          this.replyToExternalThread()
        }
      }else{
        if (this.data.internal){
          this.createInternalThread()
        }else{
          this.createExternalThread()
        }
      }
    }
  }

  handleTicketSubmit(){
    if (this.ticketNumber === undefined || this.ticketNumber === '') {
      this.hasTicketStringErrors = true
    }else{
      this.getExternalTicketByIdentifier()
    }
  }

  createInternalThread(){
    console.log('create')
    const params = {message: this.message}
    this.subs.add(
      this.supportService.createInternalThread(params)
        .subscribe(data => {
          console.log(data)
          if (data) {
            this.currentThread = data.payload
            this.chatActive = true
            this.message = ''
          }
        }, error => {
          console.error(error)
        })
    )
  }

  replyToInternalThread(){
    console.log('reply')
    const params = {message: this.message}
    this.subs.add(
      this.supportService.respondToInternalThread(params)
        .subscribe(data => {
          console.log(data)
          if (data) {
            this.currentThread = data.payload
            this.chatActive = true
            this.message = ''
          }
        }, error => {
          console.error(error)
        })
    )
  }

  replyToThreadById(){
    console.log('reply')
    const params = {
      support_request_id: this.data.selectedThread?.id,
      message: this.message
    }
    this.subs.add(
      this.supportService.respondToThreadByID(params)
        .subscribe(data => {
          console.log(data)
          if (data) {
            this.currentThread = data.payload
            this.chatActive = true
            this.message = ''
          }
        }, error => {
          console.error(error)
        })
    )
  }

  createExternalThread(){
    const params = {message: this.message}
    this.subs.add(
      this.supportService.createExternalThread(params)
        .subscribe(data => {
          console.log(data)
          if (data) {
            this.currentThread = data.payload
            this.chatActive = true
          }
        }, error => {
          console.error(error)
        })
    )
  }

  replyToExternalThread(){
    const params = {
      ticket_identifier: this.currentThread.ticket_identifier,
      message: this.message
    }
    this.subs.add(
      this.supportService.respondToExternalThread(params)
        .subscribe(data => {
          console.log(data)
          if (data) {
            this.currentThread = data.payload
            this.chatActive = true
            this.message = ''
          }
        }, error => {
          console.error(error)
        })
    )
  }

  closeDialog(){
    this.dialogRef.close(this.currentThread)
  }

  getExternalTicketByIdentifier(){
    const params = {
      ticket_identifier: this.ticketNumber
    }
    this.subs.add(
      this.supportService.getExternalThread(params)
        .subscribe(data => {
          console.log(data)
          if (data) {
            this.currentThread = data.payload
            this.chatActive = true
            this.externalChatActive = true
          }else{
            this.ticketError = true
          }
        }, error => {
          this.ticketError = true
          console.error(error)
        })
    )
  }

  setExternalChatActive(){
    this.externalChatActive = true
  }

  refreshUserCredentials(){
    if (this.emailInput === undefined || this.emailInput === ''){
      this.hasEmailInputErrors = true
    }else{
      const params = {
        email: this.emailInput
      }
      this.subs.add(
        this.supportService.refreshInvitationToken(params)
          .subscribe(data => {
            console.log(data)
            if (data) {
              Swal.fire({
                icon: 'success',
                title: 'If the provided email is valid, the user will receive an email with a password reset link',
                timer: 1500
              })
            }
          }, error => {
            console.error(error)
          })
      )
    }
  }
}
