import {Router, NavigationEnd} from '@angular/router';
import { UserService } from '../../../shared/services/user.service';
import { User } from '../../../shared/models/user';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  currentUser: User
  showSideNav = false
  private subs = new Subscription()
  private sidebarVisible: boolean

  constructor(
    private router: Router,
    private userService: UserService
  ) {
    this.currentUser = this.userService.currentUserValue
    this.sidebarVisible = false
  }

  ngOnInit(): void {
    this.subscribeToCurrentUser()
    this.subToNavigationEnd()
  }

  navigateTo(route) {
    this.router.navigate([route])

    const body = document.getElementsByTagName('body');
    const toggle = document.getElementsByClassName('toggle-nav');
    const menu = document.getElementById('nav-mobile');

    this.sidebarClose(body, toggle, menu);
  }

  subscribeToCurrentUser() {
    this.subs.add(
      this.userService.currentUser.subscribe(user => {
        if (user) {
          this.currentUser = user
        } else {
          this.currentUser = null
        }
       })
    )
  }

  subToNavigationEnd() {
    this.subs.add(
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.showSideNav = false
        }
      })
    )
  }

  logoutUser() {
    this.showSideNav = false
    this.userService.logoutUser()
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  sidebarOpen(body, toggle, menu) {
    for (let i = 0; i < body.length; i++) {
      body[i].classList.toggle('show-nav');
    }
    for (let x = 0; x < toggle.length; x++) {
      toggle[x].classList.toggle('transformed');
    }
    menu.classList.toggle('slide-in');

    this.sidebarVisible = true
  }

  sidebarClose(body, toggle, menu) {
    for (let i = 0; i < body.length; i++) {
      body[i].classList.remove('show-nav');
    }
    for (let x = 0; x < toggle.length; x++) {
      toggle[x].classList.remove('transformed');
    }
    menu.classList.remove('slide-in');

    this.sidebarVisible = false
  }

  sidebarToggle() {
    const body = document.getElementsByTagName('body');
    const toggle = document.getElementsByClassName('toggle-nav');
    const menu = document.getElementById('nav-mobile');

    if (this.sidebarVisible) {
      this.sidebarClose(body, toggle, menu);
    } else {
      this.sidebarOpen(body, toggle, menu)
    }
  }

  catchEsc(){
    const body = document.getElementsByTagName('body');
    const toggle = document.getElementsByClassName('toggle-nav');
    const menu = document.getElementById('nav-mobile');
    this.sidebarClose(body, toggle, menu)
  }
}

