import {Router} from '@angular/router';
import {Component} from '@angular/core';
import {UserService} from '../../../shared/services/user.service';

@Component({
  selector: 'app-navbar-slim',
  templateUrl: './navbar-slim.component.html',
  styleUrls: ['./navbar-slim.component.scss']
})
export class NavbarSlimComponent {
  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  navigateHome() {
    this.router.navigate(['/'])
  }

  logoutUser() {
    this.userService.logoutUser()
  }
}

