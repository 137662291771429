import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}else{
  const bugherd_script = document.createElement('script');
  bugherd_script.src = 'https://www.bugherd.com/sidebarv2.js?apikey=v5zhj7xonxkkrxcwig5jnq';
  bugherd_script.type = 'text/javascript';
  bugherd_script.async = true;
  document.getElementsByTagName('head')[0].appendChild(bugherd_script);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
