import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import { ModalsComponent } from './modals/modals.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarSlimComponent } from './navbar-slim/navbar-slim.component';
import { RouterModule } from '@angular/router';
import { TruncatedTextComponent } from './truncated-text/truncated-text.component';
import { TruncateModule } from '@yellowspot/ng-truncate';
import {MatInputModule} from '@angular/material/input';
import {SupportDialogComponent} from './support-dialog/support-dialog.component';
import {ViewDocumentDialogComponent} from './view-document-dialog/view-document-dialog.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {FaqDialogComponent} from './faq-dialog/faq-dialog.component';

@NgModule({
  declarations: [
    FooterComponent,
    ModalsComponent,
    NavbarComponent,
    NavbarSlimComponent,
    TruncatedTextComponent,
    SupportDialogComponent,
    FaqDialogComponent,
    ViewDocumentDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    TruncateModule,
    MatInputModule,
    NgxExtendedPdfViewerModule
  ],
  exports: [
    FooterComponent, ModalsComponent, NavbarComponent, TruncatedTextComponent, NavbarSlimComponent
  ]
})
export class ComponentsModule { }
