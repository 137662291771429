import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private apiRoot: string;

  constructor(
    private http: HttpClient,
    private storage: LocalStorageService,
    private router: Router
  ) {
    this.apiRoot = `${environment.apiUrl}`;
    this.currentUserSubject = new BehaviorSubject<User>(
      this.storage.getItem('currentUser')
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  setCurrentUser(user: User) {
    this.currentUserSubject.next(user);
  }

  loginByEmail(params) {
    return this.http.post<any>(`${this.apiRoot}/users/login`, params).pipe(
      map((data) => {
        // login successful if there's a user and token in the response
        const newUser = new User(data.payload);
        if (newUser && data.payload.token) {
          // store user details and token in local storage to keep user logged in between page refreshes
          this.storage.setItem('accessToken', data.payload.token);
          this.storage.setItem('currentUser', newUser);
          this.currentUserSubject.next(newUser);
        }
        return data;
      })
    );
  }

  retrieveUserProfile(params) {
    return this.http.get<any>(`${this.apiRoot}/admin/users/profile`, params);
  }

  logoutUser() {
    //this.storage.setItem('currentUser', undefined)
    //this.storage.setItem('accessToken', undefined)
    //this.storage.removeItem('currentUser')
    //this.storage.removeItem('accessToken')
    //this.currentUserSubject.next(null)
    //this.router.navigate(['/login'])
    // TODO VERIFY W/ ALAN THAT THESE ARE THE CORRECT PARAMS
    this.logout().subscribe(
      (data) => {
        // logout successful
        console.log(data);
        if (data) {
          this.storage.setItem('currentUser', undefined);
          this.storage.setItem('accessToken', undefined);
          this.storage.removeItem('currentUser');
          this.storage.removeItem('accessToken');
          this.currentUserSubject.next(null);
          this.router.navigate(['app/login']);
        } else {
          // TODO
          //logout unsuccessful -- return error to view
        }
      },
      (error) => {
        console.error(error);
        this.storage.setItem('currentUser', undefined);
        this.storage.setItem('accessToken', undefined);
        this.storage.removeItem('currentUser');
        this.storage.removeItem('accessToken');
        this.currentUserSubject.next(null);
        this.router.navigate(['app/login']);
      }
    );
  }

  logout() {
    return this.http.delete<any>(`${this.apiRoot}/users/logout`, {});
  }

  getActiveUsers() {
    return this.http.get<any>(
      `${this.apiRoot}/admin/users/get_active_users`,
      {}
    );
  }

  getArchivedUsers() {
    return this.http.get<any>(
      `${this.apiRoot}/admin/users/get_archived_users`,
      {}
    );
  }

  disableUser(params) {
    return this.http.delete<any>(`${this.apiRoot}/admin/users/destroy`, {
      params,
    });
  }

  reactivateUser(params) {
    return this.http.patch<any>(
      `${this.apiRoot}/admin/users/return_from_archive`,
      params
    );
  }

  createAdvUser(params) {
    return this.http.post<any>(
      `${this.apiRoot}/admin/users/create_adv_user`,
      params
    );
  }

  updateUser(params) {
    return this.http.post<any>(`${this.apiRoot}/admin/users/update`, params);
  }

  createOrgUser(params) {
    return this.http.post<any>(`${this.apiRoot}/users/create_org_user`, params);
  }

  globalUserSave(params) {
    return this.http.patch<any>(
      `${this.apiRoot}/users/global_user_save`,
      params
    );
  }

  addStateToUser(params) {
    return this.http.post<any>(
      `${this.apiRoot}/admin/users/create_adv_user`,
      params
    );
  }

  updateOrgUser(params) {
    return this.http.post<any>(`${this.apiRoot}/users/update_org_user`, params);
  }

  lockOrgUser(params) {
    return this.http.post<any>(`${this.apiRoot}/users/lock`, params);
  }

  refreshInvitation(params) {
    return this.http.post<any>(
      `${this.apiRoot}/admin/users/refresh_invitation`,
      params
    );
  }

  recordEndpointVisit(params) {
    return this.http.post<any>(
      `${this.apiRoot}/users/record_endpoint_visit`,
      params
    );
  }
}
