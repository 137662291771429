import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchCountyArray'
})
export class SearchCountyArrayPipe implements PipeTransform {

  transform(counties: any[], searchText: string): any {
    if (!searchText) { return counties }
    if (!counties) { return [] }

    return counties.filter(county => county?.name.toLowerCase().indexOf(searchText.toLowerCase()) === 0)
  }

}
