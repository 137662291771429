<form class="dragdrop-box" [formGroup]="faqForm" (ngSubmit)="onSubmit()">
  <div>
    <label for="question">Question:</label><br />
    <input type="text" id="question" formControlName="question" />
    <div
      *ngIf="faqForm.get('question').invalid && faqForm.get('question').touched"
      class="error"
    >
      Question is required.
    </div>
  </div>

  <div>
    <label for="answer">Answer:</label><br />
    <textarea type="text" id="answer" formControlName="answer"></textarea>
    <div
      *ngIf="faqForm.get('answer').invalid && faqForm.get('answer').touched"
      class="error"
    >
      Answer is required.
    </div>
  </div>

  <button class="input-card-button" type="submit" [disabled]="!faqForm.valid">
    SAVE
  </button>
</form>
