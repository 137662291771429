import { FaIconsModule } from './modules/fa-icons/fa-icons.module';
import { AngularMaterialModule } from './modules/angular-material/angular-material.module';
import { PipesModule } from './pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    FaIconsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    PipesModule
  ],
  providers: [
  ], exports: [
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    FaIconsModule
  ]
})
export class SharedModule { }
