import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../../shared/services/user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = this.userService.currentUserValue;
    const requiresLogin = route.data.requiresLogin || false;
    const requiresAccess = route.data.requiresAccess || false;
    if (requiresLogin) {
      if (!currentUser?.token) {
        this.router.navigate(['app/login']);
        return false;
      }
      if (requiresAccess) {
        if (currentUser.highest_access_level == 'org') {
          this.router.navigate(['app/login']);
          return false;
        }
      }
      return true;
    }
  }
}
