import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LocalStorageService} from '../../../shared/services/local-storage.service';
import {SupportDialogComponent} from '../support-dialog/support-dialog.component';
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  currentUser
  constructor(
    public dialog: MatDialog,
    private storage: LocalStorageService,
    private router: Router
  ) {
    this.currentUser = this.storage.getItem('currentUser')
  }

  ngOnInit() {}

  navigateTo(route) {
    this.router.navigate([route]).then()
  }

  openSupportDialog(){
    // Open the support dialog
    const dialogRef = this.dialog.open(SupportDialogComponent, {
      width: '60%',
      height: '60%',
      data: {
        user: this.currentUser,
        selectedThreadId: null,
        internal: true
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Closed Support Dialog')
    });
  }
}
