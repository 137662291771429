<!-- NAVBAR -->
<header>
  <div id="navbar" class="navbar fixed-top">
    <nav id="nav-main">
      <div class="container-fluid">
        <span (click)="navigateTo('/')" style="cursor: pointer">
          <a class="navbar-brand">
            <img class="pr-3" src="./assets/images/aim-logo-navbar.png" />
          </a>
        </span>
        <div class="main-menu d-none d-xl-block">
          <ul class="d-none d-xl-flex align-items-center mb-0">
            <li
              *ngIf="currentUser.highest_role === 'super_admin'"
              (click)="navigateTo('/gov-entity-orgs')"
              style="cursor: pointer"
            >
              <a>
                <img
                  class="mr-2"
                  style="width: 25px"
                  src="./assets/icons/aim-sites-icon.svg"
                />
                States/Counties
              </a>
            </li>
            <li
              *ngIf="currentUser.highest_access_level !== 'org'"
              (click)="navigateTo('/pilot-sites')"
              style="cursor: pointer"
            >
              <a
                ><img
                  class="mr-2"
                  style="width: 25px"
                  src="./assets/icons/aim-sites-icon.svg"
                />
                View Pilot Sites
              </a>
            </li>
            <li
              *ngIf="currentUser.highest_access_level === 'org'"
              (click)="navigateTo('/')"
              style="cursor: pointer"
            >
              <a>
                <img
                  class="mr-2"
                  style="width: 25px"
                  src="./assets/icons/aim-sites-icon.svg"
                />
                View Pilot Site
              </a>
            </li>
            <li
              (click)="navigateTo('/reporting/landing')"
              style="cursor: pointer"
            >
              <a>
                <img
                  class="mr-2"
                  style="width: 35px"
                  src="./assets/icons/aim-reporting-icon.svg"
                />
                View Reporting
              </a>
            </li>
            <li
              (click)="navigateTo('/all-measures/measures')"
              style="cursor: pointer"
            >
              <a>
                <img
                  class="mr-2"
                  style="width: 25px"
                  src="./assets/icons/aim-measure-icon.svg"
                />
                View All Measures
              </a>
            </li>
            <li
              *ngIf="currentUser.highest_role === 'super_admin'"
              (click)="navigateTo('/all-users/users')"
              style="cursor: pointer"
            >
              <a>
                <img
                  class="mr-2"
                  style="width: 25px"
                  src="./assets/icons/aim-users-icon.svg"
                />
                View All Users
              </a>
            </li>
            <li (click)="navigateTo('/documents')" style="cursor: pointer">
              <a>
                <img
                  class="mr-2"
                  style="width: 20px"
                  src="./assets/icons/aim-multi-document-icon.svg"
                />
                View All Documents
              </a>
            </li>
            <li (click)="navigateTo('/account')" style="cursor: pointer">
              <a> Account </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <!-- SLIDEOUT MOBILE MENU -->
  <nav id="nav-mobile" (keyup.escape)="catchEsc()" class="d-xl-none">
    <div
      class="menu-icon toggle-nav"
      (click)="sidebarToggle()"
      type="button"
      aria-controls="main-menu"
      aria-expanded="false"
      aria-label="Toggle navigation"
      tabindex="0"
    >
      <div class="hamburger"></div>
    </div>
    <div class="main-menu mobile-menu">
      <ul class="mb-0">
        <li
          *ngIf="currentUser.highest_role === 'super_admin'"
          (click)="navigateTo('/gov-entity-orgs')"
          style="cursor: pointer"
        >
          <a>
            <img
              class="mr-2"
              style="width: 25px"
              src="./assets/icons/aim-sites-icon.svg"
            />
            States/Counties
          </a>
        </li>
        <li
          *ngIf="currentUser.highest_access_level !== 'org'"
          (click)="navigateTo('/pilot-sites')"
          style="cursor: pointer"
        >
          <a>
            <img
              class="mr-2"
              style="width: 25px"
              src="./assets/icons/aim-sites-icon.svg"
            />
            View Pilot Sites
          </a>
        </li>
        <li
          *ngIf="currentUser.highest_access_level === 'org'"
          (click)="navigateTo('/')"
          style="cursor: pointer"
        >
          <a>
            <img
              class="mr-2"
              style="width: 25px"
              src="./assets/icons/aim-sites-icon.svg"
            />
            View Pilot Site
          </a>
        </li>
        <li (click)="navigateTo('/reporting/landing')" style="cursor: pointer">
          <a>
            <img
              class="mr-2"
              style="width: 35px"
              src="./assets/icons/aim-reporting-icon.svg"
            />
            View Reporting
          </a>
        </li>
        <li
          (click)="navigateTo('/all-measures/measures')"
          style="cursor: pointer"
        >
          <a>
            <img
              class="mr-2"
              style="width: 25px"
              src="./assets/icons/aim-measure-icon.svg"
            />
            View All Measures
          </a>
        </li>
        <li
          *ngIf="currentUser.highest_role === 'super_admin'"
          (click)="navigateTo('/all-users/users')"
          style="cursor: pointer"
        >
          <a>
            <img
              class="mr-2"
              style="width: 25px"
              src="./assets/icons/aim-users-icon.svg"
            />
            View All Users
          </a>
        </li>
        <li
          *ngIf="currentUser.highest_role === 'super_admin'"
          (click)="navigateTo('/documents')"
          style="cursor: pointer"
        >
          <a>
            <img
              class="mr-2"
              style="width: 20px"
              src="./assets/icons/aim-multi-document-icon.svg"
            />
            View All Documents
          </a>
        </li>
        <li (click)="navigateTo('/account')" style="cursor: pointer">
          <a> Account </a>
        </li>
      </ul>
    </div>
  </nav>
  <div class="menu-overlay"></div>
</header>
