import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FaqService } from 'src/shared/services/faq.service';

@Component({
  selector: 'app-faq-dialog',
  templateUrl: './faq-dialog.component.html',
  styleUrls: ['../footer/footer.component.scss'],
})
export class FaqDialogComponent implements OnInit {
  form: UntypedFormGroup;
  subs = new Subscription();
  errorPresent = false;
  errorMsg;
  currentThread;
  internal = true;
  faqs;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialogRef: MatDialogRef<FaqDialogComponent>,
    private faqService: FaqService
  ) {
    dialogRef
      .beforeClosed()
      .subscribe((result) => dialogRef.close(this.currentThread));
  }

  ngOnInit() {
    this.getFaqs();
  }

  closeDialog() {
    this.dialogRef.close(this.currentThread);
  }
  getFaqs() {
    this.subs.add(
      this.faqService.getAllFaq().subscribe(
        (data) => {
          if (data) {
            console.log(data.payload);
            this.faqs = data.payload;
          }
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }
}
