import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourceUploadService {
  private apiRoot: string
  private readonly bucketName = 'aimcci';

  constructor(private http: HttpClient) {
    this.apiRoot = `${environment.apiUrl}`
  }

  // UPLOAD RESOURCE:
  uploadFile(fileKey: string, file: File): Promise<any> {
    return this.http.get<{ url: string }>(`${this.apiRoot}/aws/upload?filename=${fileKey}`)
      .toPromise()
      .then(response => {
        const presignedUrl = response.url;
        return fetch(presignedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to upload file to S3');
        }
        return { Key: fileKey };
      });
  }

  // DOWNLOAD RESOURCE:
  downloadFile(fileKey: string): Promise<Blob> {
    return this.http.get<{ url: string }>(`${this.apiRoot}/aws/download?filename=${fileKey}`)
      .toPromise()
      .then(response => fetch(response.url))
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to download file from S3');
        }
        return response.blob();
      });
  }

  // DOWNLOAD RESOURCE:
  downloadDuaFile(id: number): Promise<Blob> {
    return this.http.get<{ url: string }>(`${this.apiRoot}/aws/download_dua?document_id=${id}`)
      .toPromise()
      .then(response => fetch(response.url))
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to download file from S3');
        }
        return response.blob();
      });
  }

  // DELETE RESOURCE:
  deleteFile(fileKey: string): Promise<any> {
    return this.http.delete(`${this.apiRoot}/aws/delete?filename=${fileKey}`)
      .toPromise()
      .then(response => {
        if (!response) {
          throw new Error('Failed to delete file from S3');
        }
        return response;
      });
  }
}
