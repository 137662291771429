import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService {

  constructor(private userService: UserService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let found

      const blackListedRoutes = [
        'https://aim-api.herokuapp.com/api/v1/users/login'
      ]
      for (let i = 0; i < blackListedRoutes.length; i++) {
        if (blackListedRoutes[i] === request['url']) {
          found = true;
          break;
        } else {
          found = false
        }
      }
      if (found === false) {
        if (err.status === 412) {
          // auto logout if 412 response returned from api
          this.userService.logoutUser();
        }
      }
      const error = err || err.statusText;
      return throwError(error);
    }))
  }
}
