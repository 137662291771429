import { WindowScrollingService } from '../shared/services/window-scrolling.service';
import { UserService } from '../shared/services/user.service';
import { UserResolverService } from '../shared/services/user-resolver.service';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { ErrorInterceptorService } from '../shared/services/error-interceptor.service';
import { AuthorizationHeaderService } from '../shared/services/authorization-header.service';
import { ModalService } from './components/modals/modal.service';
import { ComponentsModule } from './components/components.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularMaterialModule } from '../shared/modules/angular-material/angular-material.module';
import { AccessGuard } from './guards/access/access.guard';
import { TitleCasePipe } from '@angular/common';
import { PipesModule } from '../shared/pipes/pipes.module';
import { FaqComponent } from './faq/faq.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { FaqCardComponent } from './faq/faq-card/faq-card/faq-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
  declarations: [AppComponent, FaqComponent, FaqCardComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    ComponentsModule,
    AngularMaterialModule,
    PipesModule,
    DragDropModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule
  ],
  providers: [
    ModalService,
    AuthorizationHeaderService,
    ErrorInterceptorService,
    LocalStorageService,
    UserResolverService,
    UserService,
    WindowScrollingService,
    AngularMaterialModule,
    AccessGuard,
    TitleCasePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationHeaderService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
