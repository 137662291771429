import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import browsee from '@browsee/web-sdk'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'aim-web';
  constructor(){
    browsee.init({apiKey: environment.browseeKey})
  }
}
