import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FaqService } from 'src/shared/services/faq.service';

@Component({
  selector: 'app-faq-card',
  templateUrl: './faq-card.component.html',
  styleUrls: ['./faq-card.component.scss'],
})
export class FaqCardComponent implements OnInit {
  faqForm: UntypedFormGroup;
  @Output() formSubmitted = new EventEmitter<boolean>(); // let the parent know the form was submitted
  @Output() faqAdded: EventEmitter<void> = new EventEmitter<void>(); // let the parent know the faq was added
  @Input() faqArrayLength: number; // get the length of the array from the parent to calculate next display_order

  constructor(
    private formBuilder: UntypedFormBuilder,
    private faqService: FaqService
  ) {}

  ngOnInit(): void {
    this.faqForm = this.formBuilder.group({
      question: ['', Validators.required],
      answer: ['', Validators.required],
    });
    console.log('NEXT: ', this.faqArrayLength + 1);
  }

  onSubmit() {
    const nextDisplayOrder = this.faqArrayLength + 1;
    const form = {
      question: this.faqForm.value.question,
      answer: this.faqForm.value.answer,
      display_order: nextDisplayOrder,
    };
    this.createFaq(form);
  }

  createFaq(faq) {
    this.faqService.createFaq(faq).subscribe(
      (data) => {
        if (data.payload) {
          this.faqAdded.emit(); // Emit the faqAdded event to notify the parent component
          this.formSubmitted.emit(true); // Emit the formSubmitted event to notify the parent component
          this.faqForm.reset();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
