<div
  mat-dialog-container
  style="
    border: solid 4px #712772;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  "
  class="support-dialog"
>
  <div class="support-container">
    <div class="support-header">
      <div class="col-12">
        <h1>
          Frequently Asked Questions:
          <img
            style="float: right; cursor: pointer"
            src="./assets/icons/aim-exit-red.svg"
            (click)="closeDialog()"
          />
        </h1>
        <div class="dragdrop-list" *ngFor="let faq of faqs">
          <p>
            <b>{{ faq.question }}</b>
          </p>
          <p>{{ faq.answer }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
