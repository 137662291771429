import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FaqService } from 'src/shared/services/faq.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { User } from '../../shared/models/user';
import { LocalStorageService } from 'src/shared/services/local-storage.service';
import Swal from 'sweetalert2';
import { UntypedFormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  newFaqOrder = [];
  faqs;
  subs = new Subscription();
  adminEdit = false;
  reorderButtonMsg = 'Re-Order FAQs';
  currentUser: User;
  showAddForm = false;
  showEditForm = false;
  controls: UntypedFormArray;

  constructor(
    private faqService: FaqService,
    private storage: LocalStorageService,
    private http: HttpClient
  ) {
    this.currentUser = this.storage.getItem('currentUser');
  }

  ngOnInit(): void {
    this.getFaqs();
  }

  onFormSubmitted(submitted: boolean) {
    this.showAddForm = false; // update the showAddForm variable
  }

  getFaqs() {
    this.subs.add(
      this.faqService.getAllFaq().subscribe(
        (data) => {
          if (data) {
            this.faqs = data.payload;
          }
        },
        (error) => {
          console.error(error);
        }
      )
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.faqs, event.previousIndex, event.currentIndex);
  }

  setReorder() {
    this.adminEdit = !this.adminEdit;
    if (this.adminEdit) {
      this.reorderButtonMsg = 'Save Order of FAQs';
    } else {
      this.reorderButtonMsg = 'Re-Order FAQs';
      this.onSaveReorderFaqs(this.faqs);
    }
  }

  onSaveReorderFaqs(faqs) {
    this.newFaqOrder = []; // empty the array
    for (let newFaq of faqs) {
      // loop through faqs and write the ids to new array
      this.newFaqOrder.push(newFaq.id);
    }
    this.faqService.updateDisplayOrder({ faq_ids: this.newFaqOrder }).subscribe(
      (data) => {
        if (data) {
          this.getFaqs();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  refreshFaqs() {
    this.getFaqs();
  }

  deleteFaq(faq) {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to remove this FAQ?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: 'red',
    }).then((result) => {
      if (result.isConfirmed === true) {
        this.faqService.desleteFaq({ faq_id: faq.id }).subscribe(
          (data) => {
            if (data) {
              this.getFaqs();
            }
          },
          (error) => {
            console.error(error);
          }
        );
      }
    });
  }

  selectedFaq: any;
  editedQuestion: string = '';
  editedAnswer: string = '';

  enterEditMode(faq: any) {
    this.selectedFaq = faq;
    this.editedQuestion = faq.question;
    this.editedAnswer = faq.answer;
  }

  saveEdit() {
    const params = {
      faq_id: this.selectedFaq.id,
      question: this.editedQuestion,
      answer: this.editedAnswer,
      display_order: this.selectedFaq.display_order,
    };
    this.selectedFaq = null; // Exit edit mode
    console.log('Params: ', params);

    this.faqService.updateFaq(params).subscribe(
      (data) => {
        if (data) {
          this.getFaqs();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
