import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  private apiRoot: string;

  constructor(private http: HttpClient) {
    this.apiRoot = `${environment.apiUrl}`;
  }

  getAllFaq() {
    return this.http.get<any>(`${this.apiRoot}/admin/faqs/all`, {});
  }

  createFaq(params) {
    return this.http.post<any>(`${this.apiRoot}/admin/faqs/create`, params);
  }

  updateFaq(params) {
    return this.http.patch<any>(`${this.apiRoot}/admin/faqs/update`, params);
  }

  desleteFaq(params) {
    return this.http.delete<any>(`${this.apiRoot}/admin/faqs/destroy`, {
      params,
    });
  }

  updateDisplayOrder(params) {
    return this.http.patch<any>(
      `${this.apiRoot}/admin/faqs/update_display_order`,
      params
    );
  }
}
