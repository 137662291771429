<div  mat-dialog-container  style="border: solid 4px #712772; height: 100%; width: 100%;" class="support-dialog">
  <div class="support-container">
    <div class="support-header">
      <div class="col-12">
        <h1>
          <span *ngIf="data.internal">
            Support Requests
            <!-- Support {{chatActive ? 'Thread' : 'Request'}} -->
          </span>
          <span *ngIf="!data.internal">
            AIM CCI Support
          </span>
          <img
            style="float: right; cursor: pointer;"
            src="./assets/icons/aim-exit-red.svg"
            (click)="closeDialog()"
          >
        </h1>
      </div>
    </div>
    <div *ngIf="data.internal" style="height: 75%; overflow-y: scroll; padding-bottom: 40px;">
      <p class="px-4 py-3">Please enter a message to create a support request. All requests and responses are listed below. If you leave the site, you can return to your request by clicking Request Assistance again or by navigating to your Account screen. Someone from the AIM team will respond to your request as soon as we can. Thank you.</p>
      <div *ngIf="!chatActive" class="row">
        <div class="col-12 text-center">
          No Active Support Thread
        </div>
      </div>
      <div class="" *ngIf="chatActive">
        <div class="row m-2" *ngFor="let message of currentThread.request_messages">
          <div class="col-12 message-bubble">
            <div class="support-message" [ngStyle]="message.user.id === data.user['id'] ? {float: 'right'} : {float: 'left'}">
              <p class="mb-0 px-3 py-2" [ngStyle]="message.user.id === data.user['id'] ? {textAlign: 'right'} : {textAlign: 'left'}">
                {{message.message}}<br>
                <i>
                  - {{message.user.id ? message.user.name : 'External User' }}
                </i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!data.internal">
      <div class="row" *ngIf="!externalChatActive">
        <div class="col-7 text-center mt-4">
          <div class="px-4">
            <h1>Having trouble getting onboarding invitation or logging in? </h1>
            <div class="row justify-content-center">
              <div class="col-12 text-left">
                <input
                  id="email"
                  class="ticket-input mt-5"
                  matInput
                  (keyup)="handleEmailChange()"
                  name="Email"
                  autocomplete="off"
                  placeholder=""
                  [(ngModel)]="emailInput"
                >
                <h4 class="dialog-input-label" style="color: #B36DAC; margin-top: 5px;">
                  Email Address
                </h4>
                <h4 style="color: red">
                  &nbsp;<i *ngIf="hasEmailInputErrors">Email Required</i>
                </h4>
              </div>
              <div class="col-12 d-flex">
                <a (click)="refreshUserCredentials()" class="button-support" style="flex: 1; margin-right: 15px;">
                  Resend Invitation
                </a>
                <a (click)="refreshUserCredentials()" class="button-support" style="flex: 1;">
                  Reset Password
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5 mt-4" style="border-left: solid 2px #EBF2F5">
          <div class="px-4">
            <h1>Still having issues?</h1>
            <p>Feel free to submit a support request and we will get back with you as soon as possible!</p>
            <a (click)="setExternalChatActive()" class="button-support">Send Support Request</a>
            <hr style="border-top: 2px solid #EBF2F5; margin-top: 2.5rem; margin-bottom: 2rem;">
            <h4>Have you previously submitted a Support Request?</h4>
            <div class="mr-4">
              <input
                class="ticket-input"
                matInput
                id="ticket"
                (keyup)="handleTicketChange()"
                name="Ticket Number"
                autocomplete="off"
                placeholder=""
                [(ngModel)]="ticketNumber"
              >
            </div>
            <h4 *ngIf="!hasTicketStringErrors" class="dialog-input-label" style="color: #B36DAC; margin-top: 5px;">
              Ticket Number
            </h4>
            <h4 *ngIf="hasTicketStringErrors" class="dialog-input-label" style="color: red">
              <i>Ticket Number Required</i>
            </h4>
            <a (click)="handleTicketSubmit()" class="button-support" style="padding: 8px 18px 7px;">
              Check Current Request
            </a>
            <h4 *ngIf="ticketError" style="color: red"><i>No Ticket Found</i></h4>
          </div>
        </div>
      </div>
      <div *ngIf="externalChatActive">
        <div *ngIf="!chatActive" class="row">
          <div class="col-12 text-center pt-3">
            Please provide as much detail as possible. We will respond as soon as possible.
          </div>
        </div>
        <div *ngIf="chatActive">
          <div class="mb-4 row">
            <div class="col-12 text-center">
              <h3 class="mb-0">
                TICKET: #{{currentThread.ticket_identifier}}
              </h3>
              <p>
                <i>
                  Please retain to check this thread for updates.
                </i>
              </p>
            </div>
          </div>
          <div class="row m-2" *ngFor="let message of currentThread.request_messages">
            <div *ngIf="message.user.id" class="col-12 message-bubble">
              <div class="support-message" style="float: left">
                <p class="mb-0 p-2" style="text-align: left">
                  {{message.message}}<br>
                  {{message.user.name}}<br>
                  <i>
                    - {{message.user.name}}
                  </i>
                </p>
              </div>
            </div>
            <div *ngIf="!message.user.id" class="col-12 message-bubble">
              <div class="support-message" style="float: right">
                <p class="mb-0 p-2" style="text-align: right">
                  {{message.message}}<br>
                  <i>
                    - External User
                  </i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data.internal || externalChatActive" class="support-input-row">
      <div class="col-12">
        <p class="mt-3"><em>Messages from our support staff are not immediate. We will respond to your request as soon as possible.</em></p>
      </div>
      <div class="col-12">
        <mat-form-field class="support-input" style="border-bottom: none">
          <input
            matInput
            (keyup)="handleChange()"
            name="Message"
            autocomplete="off"
            placeholder=""
            [(ngModel)]="message"
          >
        </mat-form-field>
        <button class="submit-button" (click)="handleSubmit()">
          {{chatActive ? 'SUBMIT' : 'SUBMIT'}}
        </button>
        <div *ngIf="hasErrors" class="col-12" style="color: red">
          Message is Required
        </div>
      </div>
    </div>
  </div>
</div>
